   <template>
   <f7-page name="partsdaily">
    <f7-navbar>
      <f7-nav-left>
        <f7-link
          icon-ios="f7:chevron_left"
          icon-md="material:chevron_left"
          href="/"
          >Back</f7-link
        >
      </f7-nav-left>
      <f7-nav-title> Daily Parts </f7-nav-title>
      <f7-nav-right>
        <f7-link
          class="searchbar-enable"
          data-searchbar=".searchbar-demo"
          icon-ios="f7:search"
          icon-aurora="f7:search"
          icon-md="material:search"
        ></f7-link>
      </f7-nav-right>
      <f7-searchbar
        class="searchbar-demo"
        expandable
        search-container=".search-list"
        search-in=".item-title"
        :value="keyword"
        @input="keyword = $event.target.value"
        @keypress.native="searchParts($event)"
        @change="clear($event)"
   
      ></f7-searchbar>
    </f7-navbar>
   <!-- Toolbar-->
   <f7-toolbar bottom>
     <f7-link href="/">Home</f7-link>
      <f7-link href="/partsadd/">Add Part</f7-link>
     <f7-link href="/partsfilter/">Filter</f7-link>
   </f7-toolbar>


  

<f7-list>
  <f7-list-item
  v-for="part in parts"
        :key="part.id"
        :link="'/part/' + part.id"
        no-chevron
         >
    <div 
    class="card"
    >
    <div 
    class="card-header"
    > 
<span class="left">{{ part.vendor.name }}</span>
       <span>{{ part.user.full_name }}</span>
       <span class="right">{{  part.orderno }}</span>
        </div> 

    <div :id="'content' + part.id" class="card-content" >
      <div v-if="part.details === ''" class=""></div> 
      <div v-else ><span class="title">Detail:</span><br><span class="cont">{{ part.details }}</span> </div> 
      <div v-if="part.specialInstructions === ''" class=""></div>
        <div v-else ><span class="title">Special Request:</span><br><span class="cont"> {{  part.specialInstructions }}</span></div> 

      </div>
     
   <div>
        <f7-badge
        class="badge "
        :class="removeSpace(part._urgency.value)"
        >{{ part._urgency.value }}</f7-badge
      >
      <f7-badge
        class="badge "
        :class="removeSpace(part._action.value)"
        >{{ part._action.value }}</f7-badge
      >
      <f7-badge
        class="badge "
        :class="removeSpace(part._location.value)"
        >{{ part._location.value }}</f7-badge
      >
      </div>
      <div class="card-footer">
        <div v-if="part._status !== null" class="tstamp">
        {{ part._status.value }}
        <span v-if="part._status_by !== null">By  {{ part._status_by.full_name }} </span> 
        <span v-if="part._modified !== null">@  {{ format_timestamp(part.modified) }} </span>
      </div>
      </div>

      

</div>
</f7-list-item>
  </f7-list>    
      
 </f7-page>


</template>
<style>
.card{
  width:100%;
  
}
.card-content{
  padding: 0 10px 0 10px;
}
.title{
  font-size:.55em;
}
.badge{
  font-size:.75em;
}
.tstamp{
  font-size:.75em;
}

.panel {
  min-width: 20px;
  max-width: 10vw;
}

.row{
  min-height:30px;
}


.item{
  background-color:ghostwhite;
}

.t-col{
  font-size:1.2em;
  text-align: center;
  min-height:95%;
  width:95%;
  /* border: 1px solid black; */
} 
.eight{
  width:8%;

}
.ten{
  width:10%;
}
.twentyfour{
  width:24%;
}

.return{
  color:white;
  background-color:red;
}
.pickup{
  color:white;
   background-color:green;
}

.transfer{
  color:white;
  background-color:blue;
}

.morning{
  background-color:yellow;
}

.afternoon{
  background-color:lightblue;
}

.morning1,
.morning_1 {
    background-color: yellow;
    color: black;
}
.morning_2,
.morning2 {
    background-color: yellow;
    color: red;
}
.midday,
.dirtycore,
.dirty_core {
    background-color: orange;
    color: black;
}
.covington{
  background-color:#1E90FF;
}

.deliver, .deliveries {
        background-color: blue;
        color: gainsboro;
    }

.shop_bold{
  font-weight:bold;
}

.greenville{
  background-color:#32CD32;
}
.dayton{
  color:white;
  background-color:maroon;
}
.springfield,
.bsa {
    background-color: #e8a933;
    color:darkslategray;
}
.springfield_ud,
.bsa_ud {
    color: #e8a933;
}
.covington_color{
  color:#1E90FF;
}

.greenville_color{
 color:#32CD32;
}
.dayton_color{
  color:maroon;
}
.saturday {
    background-color: orange;
    color: black;
}
.myrun,
.my_run {
    background-color: green;
    color: white;
}
.float-right{

  float:right;
  
}
/* .navbar{
   background-color:yellow;
} */

</style>

<script>

export default {
   data: function () {
    return {
      token : '',
      uid:'',
      parts:'',
      record:'',
      search:false,
      vendors:'',
      vendor_id:'',
      keyword:'',
    
    };
   },

  async created()  {

       const credStore =  this.$vlf.createInstance({
           storeName: 'cred'
       });

await credStore.getItem('token').then((value) => (this.token = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });

await credStore.getItem('uid').then((value) => (this.uid = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });
this.loadData();
},


methods:{

  loadData(){
const data = {search:this.search,vendor_id:this.vendor_id,keyword:this.keyword,};
const url = apiUrl + "/api/dailyparts/index";

console.log(data);
axios.post(url, data, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + TOKEN,
        },
      })
      .then(response => {
      console.log(response.data)
   this.parts = response.data.dailyparts;
   this.vendors = response.data.vendors;
 })
 .catch(function(error){console.log(error)});

//  }).then(response => console.log(response.data)).catch(function(error){console.log(error)});
//.then(response => this.posts = response.data.data)
     this.$f7ready((f7) => {



      //  f7.dialog.alert('Component mounted');


     });
   },

     setVendor(val){
      //  alert(val)
       if(val == 'viewall'){
         this.vendor_id='';
       }else{
         this.vendor_id = val;
       }
        
        this.loadData();
      
     },
     lowerCase(value){
       value = value.replace(/\s/g, "");
       return value.toLowerCase();

     },
     removeSpace(val) {
      val = val.toLowerCase();
      val = val.replaceAll(" ", "_");

      return val;
    },
    show(id){
      
      $('#content' + id).show();
    },
    alert(he){
      alert(he)
    },

   
   format_timestamp(timestamp){
    var date = this.$moment(timestamp).format("YYYY-MM-DD");
    var time = this.$moment(timestamp).format("hh:mm a");
    return date + ' ' + time;
   },
   setDST(date){
   
   var dst = this.$moment(date).isDST();
   if(dst == true){
     return "-04:00";
   }else{
     return "-05:00";
   }
  },
  searchParts(event) {

        this.search = true;
        this.loadData();
       
    },
    clear(event){
      if(this.keyword ==""){
      this.search = false;
      this.loadData();
      }
    }
 }
   
 };
</script>
