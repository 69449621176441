import HomePage from "../pages/home.vue";
import AboutPage from "../pages/about.vue";
import FormPage from "../pages/form.vue";
import UnauthorizedPage from "../pages/unauthorized.vue";

// parts
import PartsPage from "../pages/parts/parts.vue";
import PartPage from "../pages/parts/part.vue";
import PartsdailyPage from "../pages/parts/partsdaily.vue";
import PartsaddPage from "../pages/parts/partsadd.vue";
import PartsprocessPage from "../pages/parts/partsprocess.vue";
import PartsfilterPage from "../pages/parts/partsfilter.vue";

// employees
import EmployeeportalPage from "../pages/employee/employeeportal.vue";
import TimeclockPage from "../pages/employee/timeclock.vue";
import LoginPage from "../pages/employee/login.vue";
import ResetPage from "../pages/employee/reset.vue";
import UpdatepwdPage from "../pages/employee/updatepwd.vue";
import ProfilePage from "../pages/employee/profile.vue";
import AppSettingsPage from "../pages/employee/appsettings.vue";

//Efficencies
import MonthlyEffPage from "../pages/employee/efficiency/monthly.vue";
import MonthlyEffTotalsPage from "../pages/employee/efficiency/monthlytotals.vue";

//Vacations
import VacationsPage from "../pages/employee/vacations/vacations.vue";
import EditVacationsPage from "../pages/employee/vacations/edit.vue";
import AddVacationsPage from "../pages/employee/vacations/add.vue";
import CalendarPage from "../pages/employee/vacations/calendar.vue";

//Data
import LinksPage from "../pages/data/links.vue";
import LinkPartsPage from "../pages/data/linkparts.vue";
import LinkFormsPage from "../pages/data/linkforms.vue";
import LinkEmployeePage from "../pages/data/linkemployee.vue";
import LinkToolsPage from "../pages/data/linktools.vue";
import LinkVisionPage from "../pages/data/linkvision.vue";
import LinkVendorsPage from "../pages/data/linkvendors.vue";

import DynamicRoutePage from "../pages/dynamic-route.vue";
import RequestAndLoad from "../pages/request-and-load.vue";
import NotFoundPage from "../pages/404.vue";
import SettingsPage from "../pages/settings.vue";
import localforage from "localforage";

//Intraconnect

//Inventory
import InventoryTransfers from "../pages/inventory/transfers.vue";

//Inspections
import InspectionListPage from "../pages/inspections/inspectionlist.vue";
import InspectionPage from "../pages/inspections/inspection.vue";
import NewInspectionPage from "../pages/inspections/newinspection.vue";

//Tools
import ToolList from "../pages/tools/list.vue";
import Tool from "../pages/tools/tool.vue";
import Device from "../pages/tools/device.vue";

//BigCommerce
import BigCommerceAddItem from "../pages/intraconnect/bigcommerce/additem.vue";

//Tasks
import MytasksPage from "../pages/tasks/mytasks.vue";
import TaskPage from "../pages/tasks/task.vue";
import AddtaskPage from "../pages/tasks/addtask.vue";
import MaintenanceRequestPage from "../pages/tasks/maintenancerequest.vue";

//Files
import UploadFilesPage from "../pages/files/uploadfiles.vue";
import UploadImagesPage from "../pages/files/uploadimages.vue";

//Sales Calls
import SalesCallListPage from "../pages/salescalls/salescalllist.vue";

//Customers
import CustomerDashboardPage from "../pages/customers/dashboard.vue";
import CustomerListPage from "../pages/customers/customerlist.vue";
import ProspectListPage from "../pages/customers/prospectlist.vue";
import CustomerPage from "../pages/customers/customer.vue";
import ProspectPage from "../pages/customers/prospect.vue";
import SalesCallPage from "../pages/customers/salescall.vue";
import AddContactPage from "../pages/customers/addcontact.vue";
import AddProspectPage from "../pages/customers/addprospect.vue";

import PartscounterRequestPage from "../pages/partscounter/partscounterrequest.vue";
import PartscounterRequestAddPage from "../pages/partscounter/partscounterrequestadd.vue";
import PartscounterPage from "../pages/partscounter/partscounter.vue";

async function checkAuth(to, from, resolve, reject) {
  const credStore = localforage.createInstance({
    storeName: "cred",
  });

  const userStore = localforage.createInstance({
    storeName: "user",
  });
  var token1 = "";
  var router = this;

  await credStore.getItem("token").then((value) => (token1 = value));
  // console.log('Test' + token1)

  userStore
    .getItem("id")
    .then(function (value) {
      const url = apiUrl + "/api/users/active";
      const data = {
        id: value,
      };
      console.log(data);
      console.log(token1);
      axios
        .post(url, data, {
          origin: true,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token1,
          },
        })
        .then(function (response) {
          console.log(response);

          if (response.data == false && token1 != null) {
            reject();
            console.log("rejected");
            console.log("Token Removed");
            credStore.setItem("token", null);

            var link = "/unauthorized/";
            router.navigate(link);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    })
    .catch(function (err) {
      // This code runs if there were any errors
      console.log(err);
    });

  credStore.getItem("token").then(function (value) {
    var token = value;
    // console.log(value);
    // console.log(token);
    if (token != null) {
      var toUrl = to.url;
      console.log(toUrl);
      var ckUrl = toUrl.split("/");

      console.log(ckUrl);
      switch (ckUrl[2]) {
        case "inspection":
        case "partscounterrequest":
          var ul = toUrl.split("#");
          link = ul[1];
          console.log(link);
          reject();
          router.navigate(link);
          console.log("past");

          break;
        default:
          console.log("resolved");
          resolve();
          break;
      }
      // if(ckUrl[2] === 'inspection' ||ckUrl[2] === 'partscounterrequest'){
      //   var ul = toUrl.split("#");
      //   link  = ul[1];
      //   console.log(link)
      //   reject();
      //   router.navigate(link);
      //   console.log('past');

      // }else{
      //   console.log('resolved');
      //   resolve();
      // }
    } else {
      var toUrl = to.url;

      var ckUrl = toUrl.split("/");
      var link = "/login/";
      if (ckUrl[2] === "updatepwd") {
        //Allows for password update page

        var ul = toUrl.split("#");
        link = ul[1];
      }

      reject();
      console.log("rejected");
      router.navigate(link);
    }
  });
}

var routes = [
  {
    path: "/",
    component: HomePage,
    beforeEnter: checkAuth,
  },
  {
    path: "/about/",
    component: AboutPage,
  },
  {
    path: "/form/",
    component: FormPage,
  },
  {
    path: "/unauthorized/",
    component: UnauthorizedPage,
  },
  //parts
  {
    path: "/parts/",
    component: PartsPage,
  },
  {
    path: "/part/:id",
    component: PartPage,
  },
  {
    path: "/partsdaily/",
    component: PartsdailyPage,
  },
  {
    path: "/partsadd/",
    component: PartsaddPage,
  },
  {
    path: "/partsfilter/",
    component: PartsfilterPage,
  },
  {
    path: "/partsprocess/",
    component: PartsprocessPage,
  },
  //employees
  {
    path: "/employeeportal/",
    component: EmployeeportalPage,
    beforeEnter: checkAuth,
  },
  {
    path: "/vacations/",
    component: VacationsPage,
  },
  {
    path: "/calendar/",
    component: CalendarPage,
  },
  {
    path: "/addvacations/",
    component: AddVacationsPage,
  },
  {
    path: "/editvacation/eventid/:eventId/shop/:shop",
    component: EditVacationsPage,
  },

  {
    path: "/monthlyEff/year/:year/month/:month",
    component: MonthlyEffPage,
  },

  {
    path: "/monthlyEffTotals/",
    component: MonthlyEffTotalsPage,
  },

  {
    path: "/timeclock/",
    component: TimeclockPage,
  },
  {
    path: "/login/",
    component: LoginPage,
  },
  {
    path: "/reset/",
    component: ResetPage,
  },
  {
    path: "/updatepwd/id/:id/passkey/:passkey",
    component: UpdatepwdPage,
  },
  {
    path: "/profile/",
    component: ProfilePage,
  },
  {
    path: "/appsettings/",
    component: AppSettingsPage,
  },
  {
    path: "/dynamic-route/blog/:blogId/post/:postId/",
    component: DynamicRoutePage,
  },
  {
    path: "/settings/",
    component: SettingsPage,
  },

  //intraconnect

  //Inventory
  {
    path: "/transfers/",
    component: InventoryTransfers,
  },

  //Tools
  {
    path: "/toollist/",
    component: ToolList,
  },
  {
    path: "/tool/:id",
    component: Tool,
  },
  {
    path: "/device/:id",
    component: Device,
  },

  //bigcommerce
  {
    path: "/additem/",
    component: BigCommerceAddItem,
  },

  //Tasks
  {
    path: "/mytasks/:uid",
    component: MytasksPage,
  },
  {
    path: "/task/:id",
    component: TaskPage,
  },
  {
    path: "/addtask/",
    component: AddtaskPage,
  },
  {
    path: "/maintenancerequest/:type",
    component: MaintenanceRequestPage,
  },
  //Partscounter
  {
    path: "/partscounterrequest/:id",
    component: PartscounterRequestPage,
  },
  {
    path: "/partscounterrequestadd/:custid",
    component: PartscounterRequestAddPage,
  },
  {
    path: "/partscounter/",
    component: PartscounterPage,
  },

  //Inspection
  {
    path: "/inspectionlist/",
    component: InspectionListPage,
  },
  {
    path: "/inspection/:id",
    component: InspectionPage,
  },
  {
    path: "/newinspection/:type/:id",
    component: NewInspectionPage,
  },

  //Files
  {
    path: "/uploadfiles/:id",
    component: UploadFilesPage,
  },

  {
    path: "/uploadimages/:id",
    component: UploadImagesPage,
  },

  //Data
  {
    path: "/links/",
    component: LinksPage,
  },
  {
    path: "/linkparts/",
    component: LinkPartsPage,
  },
  {
    path: "/linkforms/",
    component: LinkFormsPage,
  },
  {
    path: "/linkvendors/",
    component: LinkVendorsPage,
  },
  {
    path: "/linktools/",
    component: LinkToolsPage,
  },
  {
    path: "/linkemployee/",
    component: LinkEmployeePage,
  },
  {
    path: "/linkvision/",
    component: LinkVisionPage,
  },
  //Sales Calls
  {
    path: "/salescalllist/",
    component: SalesCallListPage,
  },
  //Customers
  {
    path: "/customerdashboard/",
    component: CustomerDashboardPage,
  },
  {
    path: "/customerlist/",
    component: CustomerListPage,
  },
  {
    path: "/prospectlist/",
    component: ProspectListPage,
  },
  {
    path: "/addprospect/",
    component: AddProspectPage,
  },
  {
    path: "/customer/:id",
    component: CustomerPage,
  },
  {
    path: "/prospect/:id",
    component: ProspectPage,
  },
  {
    path: "/addcontact/:id/model/:model",
    component: AddContactPage,
  },
  {
    path: "/salescall/:id/customerid/:customerid/model/:model/referer/:referer",
    component: SalesCallPage,
  },

  {
    path: "/request-and-load/user/:userId/",
    async: function (routeTo, routeFrom, resolve, reject) {
      // Router instance
      var router = this;

      // App instance
      var app = router.app;

      // Show Preloader
      app.preloader.show();

      // User ID from request
      var userId = routeTo.params.userId;

      // Simulate Ajax Request
      setTimeout(function () {
        // We got user data from request
        var user = {
          firstName: "Vladimir",
          lastName: "Kharlampidi",
          about: "Hello, i am creator of Framework7! Hope you like it!",
          links: [
            {
              title: "Framework7 Website",
              url: "http://framework7.io",
            },
            {
              title: "Framework7 Forum",
              url: "http://forum.framework7.io",
            },
          ],
        };
        // Hide Preloader
        app.preloader.hide();

        // Resolve route to load page
        resolve(
          {
            component: RequestAndLoad,
          },
          {
            context: {
              user: user,
            },
          }
        );
      }, 1000);
    },
  },
  {
    path: "(.*)",
    component: NotFoundPage,
  },
];

export default routes;
