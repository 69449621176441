export const accessMixin = {
    created() {
        console.log('Hello from dateTime')
    },
    data(){
        return {
            message: 'This is the date fromat mixin'
        }
    },
   
    methods: {
        checkAccess(items,access){
        var setAccess = false;
        items.forEach((item) => {
          
            if(access.toString().includes(item) == true){
            setAccess = true;
            }
        });
       console.log(items + access)
        return setAccess;
            },


    },
   
}