export const dateTimeMixin = {
    created() {
        // console.log('Hello from dateTime')
    },
    data(){
        return {
            message: 'This is the date fromat mixin',
        }
    },
    methods: {
        formatDate(time){
            var time = new Date(time);

            return this.$moment(time).format("YYYY-MM-DD");
            

            },


    }
}