<template>
  <f7-list>
    <f7-list-item
      title="Urgency"
      ref="urgency"
      class="urgency"
    
      smart-select
      :smart-select-params="{
        openIn: 'popup',
        searchbar: true,
        searchbarPlaceholder: 'Search',
      }"
    >
      <select name="urgency"   @change="closeList()"   v-model="urgency">
        <option value="fa">First Available Run</option>
        <option
          v-for="urgency in urgencies"
          :id="urgency.id"
          :key="urgency.id"
          :value="urgency.id"
       
        >
          {{ urgency.value }}
        </option>
      </select>
    </f7-list-item>

    <f7-list-item>
    <button  
      v-for="urgency in urgencies"
          :id="urgency.id"
          :key="urgency.id"
          :value="urgency.id"
          :class="setUrgencyClass(urgency.value)"
          @click="setUrgency(urgency.id,urgency.value)"
          >
          {{ urgency.value }}
          </button>
        </f7-list-item>

  </f7-list>
</template>
<style>
button{
  height:50px;
  font-size:.50em;
}
</style>
<script>
export default {
  props: {
    defaultBranch: [Number,String],
  },
  data() {
    return {
      title: "Urgency",
      urgencies: [],
      urgency: 0,
    };
  },
  async created() {
    const data = {
      menu: "urgencies",
      except:['NO HURRY']
    };

    const url = apiUrl + "/api/dropdowns/dropdownlist";

    await axios
      .post(url, data, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + TOKEN,
        },
      })
      .then((response) => {
        this.urgencies = response.data;
        console.log(response);
      })
      .finally(() => {
        this.$refs.urgency.f7SmartSelect.setValue('fa');
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  methods: {
    selected(urgency, id) {
      if (urgency == id) {
        return true;
      } else {
        return false;
      }
    },
    closeList() {
      this.$refs.urgency.f7SmartSelect.close();
 
    },
    alt() {
      // alert(this.defaultBranch)
    },
    setUrgencyClass(val){
      return val.toLowerCase().replace(' ','_');
    },
    setUrgency(id,val){
      this.urgency = id
      this.$emit("urgencyUpdated", val);
    this.$refs.urgency.f7SmartSelect.setValue(id);
    }
  },
  watch: {
    urgency: function (val) {
      this.$emit("urgencyUpdated", val);
    },
  },
};
</script>
