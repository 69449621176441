<template>
  <f7-page name="addpart">
    <f7-navbar title="Add Part Request" back-link="Back"></f7-navbar>
    <f7-list>
      <VendorSearch
        :vendor_id="dailypart.vendor_id"
        @vendorDetails="updateVendorDetails"
      />
    </f7-list>
    <UserSearchList
      fieldname="user_id"
      title="Requested By:"
      :defaultUser="user_id"
      @userUpdate="updateUser"
    />

    <f7-list>
      <f7-list-input
        label="Order Number"
        type="text"
        placeholder="Order number"
        clear-button
        :value="dailypart.orderno"
        @input="dailypart.orderno = $event.target.value"
      />
      <f7-list-input
        label="Details"
        type="textarea"
        placeholder="Details"
        clear-button
        :value="dailypart.details"
        @input="dailypart.details = $event.target.value"
       
      />
      <f7-list-input
        label="Special Instructions"
        type="textarea"
        placeholder="Special Instructions"
        clear-button
        :value="dailypart.specialInstructions"
        @input="dailypart.specialInstructions = $event.target.value"
      />
      <UrgencyList @urgencyUpdated="updateUrgency" />
      <ActionList @actionUpdated="updateAction" />

      <ShopList :defaultBranch="branch" @branchUpdated="updateBranch" buttons="true" />
      <f7-list>
        <f7-list-input
          label="Ready on?"
          type="date"
          :value="dailypart.readyon"
          placeholder="Please choose..."
          @input="dailypart.readyon = $event.target.value"
        />
      </f7-list>
      <f7-block strong>
        <f7-row>
          <f7-col>
            <f7-button fill color="red" @click="$f7router.back()"
              >Cancel</f7-button
            >
          </f7-col>
          <f7-col>
            <f7-button fill color="green" @click.prevent="submitRequest" :disabled="disabled"
              >Submit Request</f7-button
            >
          </f7-col>
        </f7-row>
      </f7-block>
    </f7-list>
  </f7-page>
</template>
  <script>
import UserSearchList from "../../components/UserSearchList.vue";
import VendorSearch from "../../components/VendorSearch.vue";
import ShopList from "../../components/ShopList.vue";
import UrgencyList from "../../components/UrgencyList.vue";
import ActionList from "../../components/ActionList.vue";

export default {
  components: {
    UserSearchList,
    VendorSearch,
    ShopList,
    UrgencyList,
    ActionList,
  },

  data() {
    return {
      uid: 0,
      user_id: 0,
      token: "",
      branch: "",
      dailypart: {
        orderno: "",
        requestingLocation: "",
        readyon: "",
        user_id: "",
        vendor_id:"",
      },
      disabled: true,
    };
  },
  async mounted() {
    var user = this.$vlf.createInstance({
      storeName: "user",
    });

    var credStore = this.$vlf.createInstance({
      storeName: "cred",
    });

    user
      .getItem("id")
      .then((value) => {
        this.user_id = value;
        this.dailypart.user_id = value;
      })
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });
    user
      .getItem("branch")
      .then((value) => (this.branch = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });

    credStore
      .getItem("token")
      .then((value) => (this.token = value))
      .catch(function (err) {
        // This code runs if there were any errors
        console.log(err);
      });
  },
  methods: {
    setDefaultDate() {
      let d = new Date();

      return this.$moment(d).format("YYYY-MM-DD");
    },

    updateBranch(val) {
      this.task.branch = val;
      console.log("branch:" + this.task.branch);
    },

    updateRequestby(val) {
      this.dailypart.user_id = val;
      console.log("requestby:" + this.dailypart.user_id);
    },

    submitRequest() {
      const self = this;
      const app = self.$f7;
      const router = self.$f7router;

      const data = {
        dailypart: this.dailypart,
        user_id: this.user_id,
      };
      console.log(data);

      const url = apiUrl + "/api/dailyparts/add";

      axios
        .post(url, data, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + TOKEN,
          },
        })
        .then((response) => {
          // console.log(response);
          router.back({
            force: true,
            ignoreCache: true,
          });
         
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    checkRequired() {
      this.disabled = false;
      if (this.dailypart.vendor_id == "") {
        this.disabled = true;
      }
      if (this.dailypart.user_id == "") {
        this.disabled = true;
      }
      if (this.dailypart.orderno == "") {
        this.disabled = true;
      }
      if (this.dailypart.details == "") {
        this.disabled = true;
      }
      if (this.dailypart.urgency == "") {
        this.disabled = true;
      }
      if (this.dailypart.requestingLocation == "") {
        this.disabled = true;
      }

    },
    updateVendorDetails(details) {
      this.dailypart.vendor_id = details.vendor_id;
      this.checkRequired();
    },
    updateBranch(val) {
      this.dailypart.requestingLocation = val;
      this.checkRequired();
      console.log(this.dailypart);
    },
    updateAction(val) {
      this.dailypart.action = val;
      this.checkRequired();
    },
    updateUrgency(val) {
      this.dailypart.urgency = val;
      this.checkRequired();
    },
    updateUser(val) {
      this.dailypart.user_id = val.user_id;
      this.checkRequired();
      console.log(val);
    },
  },
};
</script>

